<template>
    <div class="title_ss">RREGULLORE PËR MBAJTJEN E GARAVE TË FIZIKËS PËR NXËNËSIT E SHKOLLAVE TË MESME TË ULËTA DHE TË LARTA TË REPUBLIKËS SË KOSOVËS</div>
    <div class="text">
        <div class="sub_title">I. DISPOZITA TË PËRGJITHSHME</div>
        <div class="subsub_title">Organizatori i garave</div>
        <div class="neni">Neni 1.</div>
        Garat për nxënësit e klasëve të 9, 10, 11 dhe 12 të shkollave të mesme të ulëta
        dhe të larta në fizikë organizohen nga Shoqata Kosovare e Fizikës (në tekstin e
        mëtejmë: Shoqata) në bashkëpunim me Minisrtrinë e Arsimit, Shkencës,
        Teknologjisë dhe Inovacionit të Republikës së Kosovës dhe me institucione tjera,
        duke iu përmbajtur Udhëzimit administrativ 04/2022 të MASHTI-it për mbajtjen
        e garave. Përgjegjësinë për mbajtjen, mbarëvajtjen dhe përfundimin me sukses
        të të gjitha niveleve të garave e mban Shoqata.
    </div>
    <div class="text">
        <div class="subsub_title">Lajmërimet dhe njoftimet e Shoqatës</div>
        <div class="neni">Neni 2.</div>
        Shoqata, në fillim të çdo viti shkollor, shpall publikisht Kalendarin e garave për
        të gjitha nivelet e garave për vitin vijues shkollor me informacionet relevante në
        lidhje me to, Publikimi bëhet në faqen e internetit të Shoqatës dhe mjetet tjera
        të informimit.
        Programi i detajuar i mbajtjes së Garave shtetërore në fizikë dhe Olimpiadës
        kosovare të fizikës publikohet në faqen e internetit të paktën një javë përpara
        ditës së mbajtjes së tyre.
        Shoqata obligohet që rregullisht dhe me kohë ti informoj edhe institucionet
        përkatëse për çështjet që kanë të bëjnë me mbajtjen e garave.
    </div>
    <div class="text">
        <div class="subsub_title">Pjesëmarrja e nxënësve në gara</div>
        <div class="neni">Neni 3.</div>
        Organizimi i garave nga institucionet përgjegjëse është obligative për të gjtha
        nivelet kurse pjesëmarrja e nxënësve në gara (në tekstin e mëtejmë: garuesit)
        është vullnetare. Mësimdhënësi/mentori i garuesit duhet të informojë prindërit,
        kujdestarin ose përfaqësuesit ligjor të garuesit të mitur dhe të marrë leje për
        pjesëmarrjen e nxënësit në gara.
    </div>
    <div class="text">
        <div class="subsub_title">Puna në organizimin e garave</div>
        <div class="neni">Neni 4.</div>
        Puna në organizimin e garave bazohet kryesisht në punën vullnetare të
        pjesëmarrësve në organizimin e tyve. Në varësi të mjeteve financiare në
        dispozicion, organizatorët e garave mund të përcaktojnë punët dhe detyrat të
        cilat shpërblehen pjesërisht ose plotësisht.
    </div>
    <div class="text">
        <div class="subsub_title">Qëllimet kryesore të garave</div>
        <div class="neni">Neni 5.</div>
        Shoqata për çdo vit organizon garat në fizikë me synim që të realizohen këto qëllime kryesore:
        <ul class="list">
            <li>Ngritja e nivelit të njohurive të nxënësve në lëminë e fizikës dhe zbatimi i tyre,</li>
            <li>identifikimi dhe mbështetja e nxënësve të talentuar në lëminë e fizikës, </li>
            <li>popullarizimi i fizikës.</li>
            <li>krahasimi i njohurive të fizikës ndërmjet nxënësve dhe shkollave në nivel shtetëror dhe ndërkombëtar,</li>
            <li>njohja e të rinjve me punën e pavarur kërkimore dhe përdorimin e literaturës në fushën e fizikës,</li>
            <li>njohja e të rinjve me punën eksperimentale,</li> 
            <li>përgatitja e nxënësve për pjesëmarrje në garat ndërkombtare,</li> 
            <li>promovimi i shoqërimit të të rinjve nga shkolla dhe komuna të ndryshme,</li>
            <li>afirmimi i mësimdhënësve të spikatur për punën dhe rezultatet e arritura në lëminë e fizikës.</li>
        </ul>
    </div>
    <div class="text">
        <div class="sub_title">II GRUPET, NIVELET DHE PJESËMARRËSIT E GARAVE</div>
        <div class="subsub_title">Grupet e garave</div>
        <div class="neni">Neni 6.</div>
        Garat në fizikë, në të gjitha nivelet, ndahen në 4 grupe sipas klasëve që i ndjekin nxënësit:
        <ul class="list">
            <li><b>Grupi i parë:</b> marrin pjesë nxënësit e klasës 9 të shkollave të mesme të
            ulëta. Me leje të Shoqatës mund të marrin pjesë edhe nxënësit e talentuar të
            klasëve më të ulëta.</li>
            <li><b>Grupi i dytë:</b> marrin pjesë nxënësit e klasës 10 të shkollave të mesme të larta.</li>
            <li><b>Grupi i tretë:</b> marrin pjesë nxënësit e klasës 11 të shkollave të mesme të larta.</li>
            <li><b>Grupi i katërtë:</b> marrin pjesë nxënësit e klasës 12 të shkollave të mesme të larta.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Nivelet e garave</div>
        <div class="neni">Neni 7.</div>
        Garat në fizikë për nxënësit e shkollave të mesme të ulëta dhe të larta do të
        organizohen në tri nivele dhe do të emërohen si vijon:
        <ul class="list">
            <li><b>Garat shkollore në fizikë</b> (synohen të mbahen gjatë janarit të çdo viti)</li>
            <li><b>Garat komunale në fizikë</b> (synohen të mbahen të shtunën e javës së dytë të shkurtit të çdo viti)</li>
            <li><b>Garat shtetërore në fizikë</b> (synohen të mbahen të shtunën e javës së fundit të shkurtit të çdo viti)</li>
            <li><b>Olimpiada kosovare e fizikës</b> (synohet të mbahet të shtunën e javës së dytë të prillit të çdo viti)</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Kategoritë e garave</div>
        <div class="neni">Neni 8.</div>
        Garat në fizikë (nga sivjet) do të organizohen në dy kategori:
        <br><b>I. Garat në njohuritë teorike të fizikës</b>
        <br><b>II. Garat në njohuri dhe shkathtësi eksperimentale të fizikës</b>
        <ul class="list">
            <li><b>Garat shkollore në fizikë</b> (synohen të mbahen gjatë janarit të çdo viti)</li>
            <li><b>Garat komunale në fizikë</b> (synohen të mbahen të shtunën e javës së dytë të shkurtit të çdo viti)</li>
            <li><b>Garat shtetërore në fizikë</b> (synohen të mbahen të shtunën e javës së fundit të shkurtit të çdo viti)</li>
            <li><b>Olimpiada kosovare e fizikës</b> (synohet të mbahet të shtunën e javës së dytë të prillit të çdo viti)</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Organizatorët, pjesëmarrsit dhe përgjegjësia për mbajtjen e Garave</div>
        <div class="neni">Neni 9.</div>
        <ol type="a">
            <li><b>Garat shkollore:</b> organizohen nga aktivi i mësimdhënësve të fizikës në
            shkolla. Bazuar në rezultatet e arritura në këto gara, nxënësit marrin pjesë
            në Garat komunale. Niveli përfundimtar i garave për shkollat e mesme të
            ulëta është Gara shetërore.</li>
            <li><b>Garat komunale:</b> organizohen nga DKA komunale. Në Garat komunale
            marrin pjesë vetëm nxënësit të cilët kanë arritur rezultatet më të mira në
            Garat shkollore. Nxënësi regjistrohet në Garat komunale me
            mësimdhënësi/mentorin e tij të fizikës. Numrin e nxënësve pjesëmarrës në
            Garat komunale nga secila shkollë e përcakton DKA komunale.
            Drejtoritë komunale të arsimit i dërgojnë Shoqatës listën e nxënësve fitues
            në Garat komunale dhe të cilët do të marrin pjesë në Garat shtetërore të
            fizikës. Përgjegjëse për mbajtjen e Garave shkollore dhe komunale janë DKA-
            të.</li>
            <li><b>Garat shtetërore:</b> organizohen nga Shoqata Kosovare e Fizikës. Në Garat
            shtetërore marrin pjesë nxënësit të cilët janë në listën e dërguar nga DKA-të
            komunale. Numrin e nxënësve pjesëmarrës në Garat shtetërore për çdo
            komunë e përcakton Shoqata varësisht nga madhësia e komunës dhe
            rezultateve të arritura në Garat komunale. Përgjegjëse për mbajtjen e
            Garave shtetërore është Shoqata. Fituesit e Garës shtetërore marrin pjesë në
            Olimpiadën kosovare të fizikës.
            Garuesit e olimpiadave të vitit të kaluar mund të marrin pjesë drejpërdrejt në Garat 
            shtetërore. Me kërkesë të arsyetuar të garuesit (nuk janë mbajtë garat komunale në atë komunë, garuesi nuk ka
            qenë në Kosovë në kohën e garave komunale etj), Shoqata ka të drejtë të lejoj pjesëmarrjen e
            drejtpërdrejtë në Garat shtetërore.</li>
            <li><b>Olimpiada kosovare e fizikës:</b> organizohet nga Shoqata. Në Olimpiadën
            kosovare të fizikës marrin pjesë garuesit, të cilët kanë fituar vende në Garat
            shtetërore (përveç grupit 1) dhe janë përzgjedhur nga Komisioni shtetëror.
            Në Olimpiadën kosovare të fizikës, me leje të Shoqatës, mund të marrin
            pjesë edhe garues mysafirë (edhe nga shtetet tjera) por pa të drejtë
            konkurrence.</li>
        </ol>
        Në përzgjedhjen e pjesëmarrësve për Olimpiadën kosovare të fizikës marrin
        pjesë fituesit e Garave shtetërore të fizikës. Komisioni shtetëror përzgjedh nxënësit më të mirë që do të
        marrin pjesë në Olimpiadën kosovare e fizikës.
    </div>
    <div class="text">
        <div class="sub_title">III. GARAT NË NJOHURI DHE SHKATHTËSI EKSPERIMENTALE TË FIZIKËS</div>
        <div class="subsub_title">Garimi me punë eksperimentale</div>
        <div class="neni">Neni 10.</div>
        Nga sivjet, në Olimpiadën kosovare të fizikës, nxënësit mund të garojnë edhe
        me punime eksperimentale. Fituesit e garave shtetërore mund të paraqesin
        punën e tyre të pavarur eksperimentale (së bashku me mentorin).drejtpërdrejt
        në Shoqatë, në e-mailin: shoqatakosovareefizikes@gmail.com.
    </div>
    <div class="text">
        <div class="subsub_title">Kushtet e punës eksperimentale</div>
        <div class="neni">Neni 11.</div>
        Nga sivjet, në Olimpiadën kosovare të fizikës, nxënësit mund të garojnë edhe
        me punime eksperimentale. Fituesit e garave shtetërore mund të paraqesin
        punën e tyre të pavarur eksperimentale (së bashku me mentorin).drejtpërdrejt
        në Shoqatë, në e-mailin: shoqatakosovareefizikes@gmail.com.
        <ol type="a">
            <li>Tema e punimit eksperimental mund të jetë e bazuar në programin shkollor por nuk është obligative.</li>
            <li>Të jenë eksperimente nga të cilat fitohet një (apo më shumë) rezultate nga matja e madhësive fizike.</li>
            <li>Të jenë eksperimente interesante, origjinale ose punime që lidhin fizikën dhe shkencat e tjera (p.sh. ekologjia, mjekësia, etj.).</li>
            <li>Nuk do të pranohen punët e zakonshme eksperimentale apo vetëm
            demonstruese. Puna eksperimentale duhet të jetë origjinale në kuptimin që
            nuk është paraqitur më parë në mënyrë publike, në mjete të informimit
            (internet), në ndonjë garë apo manifestim shkencor.</li>
            <li>Vendimi përfundimtar për pranimin e punës eksperimentale do të varet edhe
            nga mundësia e demonstrimit të eksperimentit, vlerësimi i gabimeve eksperi-
            mentale, përpunim i të dhënave eksperimentale, fitingun e lakoreve et.</li>
        </ol>
    </div>
    <div class="text">
        <div class="subsub_title">Përzgjedhja e punimeve eksperimentale</div>
        <div class="neni">Neni 12.</div>
        Përzgjedhja e punimeve eksperimentale bëhet nga Komisioni i garave olimpike si vijon:
        <ul class="list">
            <li>Nga punimet eksperimentale të paraqitura, Komisioni i garave olimpike do të
            zgjedhë punimet eksperimentale të cilat plotësojnë kushtet e mësipërme
            themelore dhe sipas nevojës do ti informoj garuesit me udhëzime të mëtejshme
            (në faqen e internetit të Shoqatës).</li>
            <li>Pas dërgimit të versionit përfundimtar, Komisioni shtetëror i garave do të
            publikon listën e nxënësve që do të ftohen me punimet e tyre eksperimentale në
            Olimpiadën kosovare të fizikës.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Vlerësimi i punës eksperimentale</div>
        <div class="neni">Neni 13.</div>
        Vlerësimi i punimeve eksperimentale bëhet nga Komisioni olimpik sipas këtyre
        kritereve të vlerësimit:
        <ul class="list">
            <li>Nvlerësimi i të gjithë punës eksperimentale,</li>
            <li>Pvlerësimi i prezentimit dhe shpjegimit të punës eksperimentale nga vet nxënësi</li>
            <li>vlerësimi i mbrojtjes së punës eksperimentale (përgjigje në pyetjet e komisionit).</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Raporti i vlerësimit të punës eksperimentale/teorike</div>
        <div class="neni">Neni 14.</div>
        Numri i pikëve të punës eksperimentale dhe i pikëve të detyrave teorike është i barabartë.
    </div>
    <div class="text">
        <div class="sub_title">IV. PJESËMARRJA NË OLIMPIADA NDËRKOMBTARE</div>
        <div class="subsub_title">Përzgjedhja e pjesëmarrësve në olimpiada ndërkombtare</div>
        <div class="neni">Neni 15.</div>
        Lista përfundimtare e ekipit(ve) olimpik të Kosovës (si dhe dy anëtarë rezervë)
        përpilohet nga Shoqata në bazë të rezultateve arritura në Olimpiadën kosovare e
        fizikës dhe kushteve tjera për të cilat nxënësit duhet të njihen paraprakisht
        (rezultatet në Garat shtetërore, pjesëmarrja aktive në trajnime, zgjidhja e
        detyrave të dhëna nga mentorët etj) si dhe duke iu përmbajtur rregullores të
        olimpiadës nikoqire. Fituesi i vendit të parë mund të merrë pjesë në dy

        olimpiada kurse të tjerët në njërën nga olimpiadat ndërkombtare. Numrin e
        pjesëmarrësve në olimpiada ndërkomtare e përcakton Kryesia e Shoqatës.
        Në Olimpiada ndërkombtare nuk mund të marrin pjesë nxënësit që nuk kanë
        arritur së paku 15% të pikëve të përgjithshme në Olimpiadën kosovare e fizikës.
    </div>
    <div class="text">
        <div class="sub_title">V. DETYRAT PËR GARA</div>
        <div class="subsub_title">Detyrat e garave</div>
        <div class="neni">Neni 16.</div>
        Të gjitha nivelet e garave zhvillohen sipas programit dhe rregullave që vlejnë për
        të gjithë Republikën e Kosovës. Detyrat për garat në fizikë përpilohen në bazë të
        kurrikulës së fizikës për shkolla të mesme të ulëta përkatësisht të larta por edhe
        në përputhje me interesat dhe aftësitë e nxënësve për të hulumtuar përmbajtjet
        të cilat nuk janë në programe të rregullta. Detyrat e garave përgatiten në gjuhën
        shqipe. Komisioni i garave, pas kërkesës të garuesit, i përkthen detyrat edhe në
        gjuhën e kërkuar.
    </div>
    <div class="text">
        <div class="sub_title">V. DETYRAT PËR GARA</div>
        <div class="subsub_title">Përpilimi i detyrave</div>
        <div class="neni">Neni 17.</div>
        Komisioni i garave shtetërore i përpilon detyrat për të gjitha nivelet e garave,
        përveç atij shkollor.
        Në të gjitha nivelet e garave, garuesit i zgjidhin detyrat teorike në formë të
        shkruar.
        Në Olimpiadën kosovare të fizikës ato mund të jenë gjithashtu edhe punime
        eksperimentale
    </div>
    <div class="text">
        <div class="subsub_title">Përmbajtja e detyrave</div>
        <div class="neni">Neni 18.</div>
        Detyrat në të gjitha nivelet e garave përfshijnë materialin e kaluar të klasës
        përkatëse si dhe materialin e të gjitha klasave të mëparshme; kështu detyrat e
        klasës 12 e përfshijnë tërë programin e shkollës së mesme (shih Shtesa). Numri
        i detyrave nga klasët e mëparshme rritet sipas rritjes së klasëve më të larta.
    </div>
    <div class="text">
        <div class="subsub_title">Formati i detyrave komunale</div>
        <div class="neni">Neni 19.</div>
        Shoqata, i dërgon testet për klasat 9, 10, 11 dhe 12 me detyrat të njëjta të
        gjitha DKA të Kosovës. Çdo test do të përmbaj nga 4 - 5 detyra me disa opsione
        ku vetëm njëri opsion është i saktë. Nxënësi duhet ta zgjidh detyrën dhe ta
        shënoj opsionin që përmban rezultatin e saktë të zgjidhjes së detyrës.
    </div>
    <div class="text">
        <div class="subsub_title">Vështirësia e zgjidhjes së detyrave</div>
        <div class="neni">Neni 20.</div>
        Vështirësia e zgjidhjes së detyrave të garave duhet të jetë përafërsisht si niveli i
        detyrave të garave përkatëse regjionale dhe ndërkombtare.
    </div>
    <div class="text">
        <div class="subsub_title">Diskrecioni i detyrave dhe rezultateve</div>
        <div class="neni">Neni 21.</div>
        Në të gjitha nivelet e garave, nxënësit i zgjidhin detyrat në testet e emërtuara
        me një shifër. Deshifrimi i punimeve bëhet vetëm pasi të jenë zgjidhur ankesat
        dhe publikohen rezultatet përfundimtare.
        Në të gjitha nivelet e garave, komisionet e garave janë përgjegjëse për mbarë-
        vajtjen e garave si dhe fshehtësinë e detyrave dhe zgjidhjeve deri sa ato të
        bëhen publike.
    </div>
    <div class="text">
        <div class="sub_title">VI. PROCESI I MBATJES SË GARAVE</div>
        <div class="subsub_title">Mjetet që mund të ketë garuesi në gara</div>
        <div class="neni">Neni 22.</div>
        Në të gjitha nivelet e garave, nxënësit mund të përdorin mjetet e tyre të
        shkrimit dhe vizatimit, një kalkulator xhepi të pa programueshëm, një mjet
        gjeometrik (vizore apo trekëndësh) dhe materiale tjera të cilat i vë në
        dispozicion organizatori i garave. Gjatë garave, nxënësi nuk duhet të ketë asnjë
        material të shkruar (libra, fletore, dhe mjete të ngjashme). telefona celularë ose
        pajisje tjera elektronike.
    </div>
    <div class="text">
        <div class="subsub_title">Kohëzgjatja e garave</div>
        <div class="neni">Neni 23.</div>
        Zgjidhja e detyrave në Garat komunale zgjat 120 minuta kurse në Garat
        shtetërore 150 minuta. Olimpiada kosovare e fizikës mund të zgjat dy ditë, në
        një ditë zgjidhen detyra teorike për 150 minuta ndërsa në ditën e dytë, puna
        eksperimentale me kohëzgjatje të njëjtë.
        Gjatë 15 minutave nga fillimi i garave ose 15 minutave deri në përfundimin
        garave, garuesi nuk mund të largohet nga klasa në të cilën zhvillohet gara.
    </div>
    <div class="text">
        <div class="subsub_title">Procesi i mbajtjes së Garave komunale</div>
        <div class="neni">Neni 24.</div>
        Garat komunale për nxënësit e klasëve të 9, 10, 11 dhe 12 do të mbahen në të
        gjitha komunat e Kosovës në datë dhe orë të njëjtë. Fillimi i garave saktë në
        orën e përcaktuar është obligativ. Në ditën e mbajtjes së garave, 1 orë para
        fillimit të tyre Shoqata me email do tia dërgon testet e detyrave drejtorit të
        arsimit ose personit të autorizuar nga ai (zakonisht caktohet një koordinator i
        Garave komunale) kurse 15 min para përfundimit të garave, Shoqata përsëri me
        email, do të dërgoj edhe çelësin dhe zgjidhjet e detyrave.
    </div>
    <div class="text">
        <div class="subsub_title">Regjistrimi i pjesëmarrësve në Garat shtetërore</div>
        <div class="neni">Neni 25.</div>
        Regjistrimi i pjesëmarrësve në Garat shtetërore bëhet në bazë të
        Fletëregjistrimeve të dërguara nga DKA-të brenda afatit dhe mënyrës së
        përcaktuar në këtë Rregullore. Pas këtij afati, regjistrimet nuk janë më të
        mundshme. Në Garat shtetërore marrin pjesë vetëm garuesit e regjistruar,
        emrat e të cilëve gjenden në Listën e pjesëmarrësve të Garave shtetërore të
        publikuar së paku 3 ditë para fillimit të garave.
    </div>
    <div class="text">
        <div class="subsub_title">Procesi i mbajtjes së Olimpiadës kosovare e fizikës</div>
        <div class="neni">Neni 26.</div>
        Në Olimpiadën kosovare të fizikës marrin pjesë vetëm garuesit të cilët janë
        përcaktuar nga Komisioni olimpik i garave. Detyrat në Olimpiadën kosovare të
        fizikës përfshijnë të gjithë programin e shkollës së mesme dhe të gjithë garuesit
        garojnë në grup të njejtë të garave; me detyra të njejta teorike (ose përpunimin
        e rezultateve të matjeve) si dhe me punët (e pavarura) eksperimentale që për
        nga forma dhe vështirësia janë të ngjashme me ato në olimpiadat
        ndërkombëtare të fizikës.
    </div>
    <div class="text">
        <div class="subsub_title">Publikimi i zgjidhjeve të detyrave të garave</div>
        <div class="neni">Neni 27.</div>
        Pas publikimit jozyrtar të rezultateve të garave, në faqen e internetit të
        Shoqatës, publikohen detyrat me zgjidhje për të gjitha nivelet e garave (përveç
        shkollore).
    </div>
    <div class="text">
        <div class="subsub_title">Sjellja e nxënësve gjatë garave</div>
        <div class="neni">Neni 28.</div>
        Pas publikimit jozyrtar të rezultateve të garave, në faqen e internetit të
        Shoqatës, publikohen detyrat me zgjidhje për të gjitha nivelet e garave (përveç
        shkollore).
    </div>
    <div class="text">
        <div class="subsub_title">Sjellja e nxënësve gjatë garave</div>
        <div class="neni">Neni 28.</div>
        <ul class="list">
            <li>Nëse garuesi nuk i respekton dispozitat e kësaj Rregulloreje dhe udhëzimet e Monitoruesit të garave, Monitoruesi mund ta përjashtoj garuesin nga gara.</li>
            <li>Nxënësit janë përgjegjës për çdo sjellje të paligjshme gjatë pjesëmarrjes në
            gara (mosmbajtja e qetësisë, pengimi i nxënësve tjerë, etj.), Në raste të tilla
            mund të ndodhë përjashtimi nga gara.</li>
            <li>Përjashtimi i një garuesi nga gara shënohet në procesverbal së bashku me arsyen e përjashtimit.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Komunikimi me komisionet e garave</div>
        <div class="neni">Neni 29.</div>
        Komunikimi i nxënësve/mentorit me Komisionet e garave të të gjitha niveleve kryhet vetëm në mënyrë elektronike përmes e-mailit.
    </div>
    <div class="text">
        <div class="subsub_title">Komunikimi i palejueshëm me stafin e garave</div>
        <div class="neni">Neni 30.</div>
        Çdo lloj komunikimi i garuesve, mësimdhënësve, prindërve, familjarëve etj me
        qëllim të presionit në stafin e garave konsiderohet jo i ligjshëm dhe mund të
        pasoj me suspendimin e menjëhershëm të garuesit në pjesëmarrjen e
        mëtejshme në gara dhe të sanksionohet sipas akteve ligjore në fuqi.
    </div>
    <div class="text">
        <div class="sub_title">VII. DETYRAT E PERSONAVE PËRGJEGJËS PËR MBARËVAJTJEN E GARAVE</div>
        <div class="subsub_title">Detyrat dhe përgjegjësitë e DKA-ve</div>
        <div class="neni">Neni 31.</div>
        <ul class="list">
            <li>Përcakton Koordinatorin e Garave komunale dhe njofton Shoqatën. Pastaj, të
            gjitha komunikimet zyrtare Shoqata i kryen me Koordinatorin e Garave
            komunale.</li>
            <li>Siguron kushtet dhe mjetet për pjesëmarrjen e fituesve të Garave komunale në Garat shtetërore.</li>    
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Monitoruesit të garave</div>
        <div class="neni">Neni 32.</div>
        Në të gjitha nivelet e garave, ecuria e garave përcillet nga të paktën një
        Monitorues në çdo klasë, i cili ka mundësinë e zëvendësimit. Monitoruesi,
        përcaktohet nga kryetari i komisionit të garave përkatëse dhe ka këto detyra:
        <ul class="list">
            <li>Nga kryetari i komisionit të garave pranon dhe pastaj i shpërndan testet e
            garave dhe në fund të garës i mbledh ato duke u kujdesur që çdo garues të
            dorëzoj testin e vet.</li>
            <li>regjistron garuesit e pranishëm ashtu që çdo garues shkruan shifrën dhe
            nënshkrimin e tij</li>    
            <li>regjistron garuesit e paraqitur për pjesëmarrje por që mungojnë.</li>
            <li>siguron mbarëvajtjen e garave</li>
            <li>pas përfundimit të garës i mundëson garuesit të fotografoj testin dhe zgjidhjet e veta me telefon.</li>
            <li>pas përfundimit të garave, të gjitha materialet e garave ia dorëzon Kryetarit të komisionit.</li>    
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Koordinatorit të garave komunale</div>
        <div class="neni">Neni 33.</div>
        <ul class="list">
            <li>Organizon garat në komunën e vet,</li>
            <li>Lajmëron të gjitha shkollat e mesme të ulëta dhe të larta, publike dhe private
            në komunën e saj për terminet kohore se kur mund të mbahen Garat
            shkollore.</li>    
            <li>Lajmëron të gjitha shkollat e mesme të ulëta dhe të larta, publike dhe
            private, për vendin, datën dhe orën e mbajtjes së Garave komunale.</li>
            <li>Përcakton numrin e nxënësve nga shkollat e mesme të ulëta dhe të larta,
            publike dhe private që do të marrin pjesë në Garat komunale (zakonisht të
            gjithë fituesit e vendit 1, 2, 3 të Garave shkollore).</li>
            <li>Në bashkëpunim me degën e Shoqatës në atë Komunë dhe mësimdhënësit e
            fizikës formon Komisionin e garave komunale. Anëtarët e Komisionit e
            zgjedhin Kryetarin e komisionit.</li>
            <li>Listën përfundimtare me pikët dhe vendet e fituara të të gjithë garuesve si
            dhe Fletëregjisrimin për pjesëmarrje në Garat shtetërore, të vënë në
            dispozicion nga Komisioni komunal i Garave, ia dërgon Shoqatës më së voni
            5 ditë pas përfundimit të Garave komunale. Pas këtij afati regjistrimet për
            pjesëmarrje në Garat shtetërore nuk do të jenë të mundshme.</li>    
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Komisionit të garave komunale</div>
        <div class="neni">Neni 34.</div>
        Komisionet e garave komunale:
        <ul class="list">
            <li>bëjnë vlerësimin e punimeve të garuesve, rangimin e tyre në bazë të pikëve
            të fituara dhe publikojnë rezultatet jozyrtare (me shifra) jo më vonë se 1 ditë
            pas garave.</li>
            <li>E zgjidhë çdo ankesë në mënyrën e përshkruar në këtë rregullore, (shih
            nenin 40).</li>    
            <li>Publikon listën përfundimtare me pikët dhe vendet e fituara të gjithë
            garuesve jo më vonë se 3 ditë pas garave.</li>
            <li>Shpallë fituesit e Garave komunale dhe garuesit që do të marrin pjesë në
            Garat shtetërore së bashku me emrat dhe mbiemrat e
            mësimdhënësve/mentorëve të tyre.</li>
            <li>Listën përfundimtare me pikët e fituara të të gjithë garuesve dhe vendet e
            fituara ia dërgon DKA.</li>
            <li>Plotëson dhe nënshkruan Fletëparaqitjet për pjesëmarrje në Garat shtetërore të cilin ia dërgon DKA.</li>    
            <li>Kryetari i komisionit i ruan punimet e garuesve deri në fund të vitit shkollor,
            të cilat sipas nevojës mund ti vihen në dispozicion DKA- së dhe Shoqatës,
            dhe më pas siguron asgjësimin e tyre.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Administratorit të garave shtetërore</div>
        <div class="neni">Neni 35.</div>
        <ul class="list">
            <li>Administron mbarëvajtjen e të gjitha niveleve të garave (përveç garave shkollore)</li>
            <li>Njofton DKA-të për të gjitha çështjet që kanë të bëjnë me Garat komunale.</li>    
            <li>Siguron ambientet ku mbahen Garat shtetërore.</li>
            <li>Bën përgaditjen teknike dhe të kopjeve të nevojshme të testeve të detyrave të cilat ia dorëzon Kryetarit të komisionit të Garave shtetërore.</li>
            <li>Publikon të gjitha njoftimet që kanë të bëjnë me garat shtetërore</li>
            <li>PI përgaditë çertifikatat dhe shpërblimet tjera sipas udhëzimeve të Kryesisë së Shoqatës.</li>    
            <li>Kujdeset për arkivimin e dokumentacionit të garave.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Koordinatorit të garave shtetërore</div>
        <div class="neni">Neni 36.</div>
        <ul class="list">
            <li>Është përgjegjës për fillimin, mbarëvajtjen dhe përfundimin me sukses të Garave shtetërore.</li>
            <li>Siguron ambientet ku do të mbahen Garat shtetërore.</li>    
            <li>I vendos garuesit nëpër klasët dhe vendet e caktuara .</li>
            <li>Shpërndan dhe mbledh testet e garave tek Monitoruesit si dhe material tjetër (ujë etj)</li>
            <li>Testet e përfunduara të garuesve i dorëzon Kryetarit të komisionit të garave shtetërore.</li>
            <li>Gjatë mbajtjes së garave komunikon me personat përgjegjës duke siguruar mbarëvajtjen e garave.</li>    
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Detyrat dhe përgjegjësitë e Komisionit të garave shtetërore</div>
        <div class="neni">Neni 37.</div>
        Kryesia e Shoqatës e zgjedh Komisionin e garave shtetëror dhe Kryetarin e saj. Komisioni shtetëror i:
        <ul class="list">
            <li>emëron koordinatorin, personat përgjegjës, autorët dhe vlerësuesit e detyrave.</li>
            <li>vlerëson punimet e garuesve, vendosin numrin e pikëve të arritura dhe
            publikojnë rezultatet jozyrtare (me shifra) jo më vonë se 3 ditë pas
            pëtfundimit të Garave shtetërore.</li>    
            <li>zgjidhë të gjitha ankesat në mënyrën e përshkruar në këtë Rregullore.</li>
            <li>shpallë listën përfundimtare me pikët dhe vendet e fituara të të gjithë garuesve jo më vonë se 7 ditë pas garave.</li>
            <li>shpallë fituesit e Garave shtetëore të fizikës dhe garuesit që do të marrin pjesë në Olimpiadën kosovare të fizikës së bashku me emrat dhe mbiemrat ementorëve të tyre.</li>
            <li>Pas shqyrtimit të kërkesës, vendos për pjesëmarrjen e garuesve në gara
            shtetërore për garuesit që me arsye nuk i kanë kaluar garat komunale (nuk
            janë mbajtë garat komunale etj).</li>    
            <li>Kryetari i komisionit i ruan punimet e garuesve për një vit dhe më pas siguron asgjësimin e tyre.</li>
        </ul>
    </div>
    <div class="text">
        <div class="sub_title">VIII. ANKESAT</div>
        <div class="subsub_title">E drejta e ankesës</div>
        <div class="neni">Neni 38.</div>
        Në çdo nivel të garave, pas shpalljes jozyrtare të rezultateve (që bëhet vetëm
        me shifra dmth pa emra të garuesve), garuesi ka të drejtë të parashtroj ankesë
        në një letër me shkrim me bashkëngjitje (atachment) ekskluzivisht përmes
        emailit zyrtar ndaj rezultatit të shpallur, brenda afatit të caktuar.
    </div>
    <div class="text">
        <div class="subsub_title">Përmbajtja e ankesës</div>
        <div class="neni">Neni 39.</div>
        Ankesa duhet të përmbaj:
        <ul class="list">
            <li>shifrën e njejtë me ate të testit</li>
            <li>pretendimet e nxënësit të paraqitura qartë dhe saktë (detyrën, formulën, rezultatin, grafikun etj) të nënshkruara nga mësimdhënësi/mentori.</li>    
            <li>Ankesat e paarsyeshme nuk merren parasysh. Poashtu nuk do të shqyrtohen
            ankesat e formës psh: më kontrolloni edhe njëherë detyrën(at), më vlerësoni
            më tepër detyrën(at) etj.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Përgjigja në ankesë</div>
        <div class="neni">Neni 40.</div>
        Komisioni përkatës i garave duhet ti jap përgjigje me shkrim përmes emailit
        ankuesit në përputhje me nenin 38 dhe 39. Nëse ankuesi ka të drejtë në
        pretendi-min e tij atëherë rezultatet preliminare përmisohen dhe shpallen
        rezultatet përfundimtare.
    </div>
    <div class="text">
        <div class="sub_title">X. TRAJNIMET</div>
        <div class="subsub_title">Trajnimet për pjesëmarrje në olimpiada ndërkombtare</div>
        <div class="neni">Neni 41..</div>
        Pas përfundimit të garave shtetërore, Shoqata, sipas mundësive financiare,
        organizon përgatitjet për olimpiada ndërkombtare. Në këtë trajnim marrin pjesë
        fituesit e Garave shtetërore.
        Në këtë trajnim, me leje të Shoqatës, mund të marrin pjesë edhe nxënës të
        tjerë por pa të drejtë për të marrë pjesë në Olimpiadën kosovare të fizikës.
    </div>
    <div class="text">
        <div class="subsub_title">Trajnimet për pjesëmarrje në olimpiada ndërkombtare</div>
        <div class="neni">Neni 42.</div>
        Shoqata mund të ofrojë trajnime profesionale për mësimdhënësit/mentorët e
        interesuar në lidhje me përgaditjen e nxënësve për gara. Trajnimet e tilla i
        organizon Kryesia e Shoqatës.
    </div>
    <div class="text">
        <div class="sub_title">IX. SHPËRBLIMET DHE MIRËNJOHJET</div>
        <div class="subsub_title">Formati i shpërblimeve dhe mirënjohjeve</div>
        <div class="neni">Neni 43.</div>
        Kryesia e Shoqatës vendos për llojin dhe formatin e çertifikatave, mirënjohjeve
        dhe shpërblimeve; të jenë të disponueshme vetëm në formë elektronike, si
        dokumente të printuara ose në format tjetër.
    </div>
    <div class="text">
        <div class="subsub_title">Fituesit e garave dhe kush shpërblehet</div>
        <div class="neni">Neni 44.</div>
        Fitues të të gjitha niveleve të garave konsiderohen garuesit të cilët kanë fituar
        vendin e parë, të dytë të tretë, mirënjohje nderi apo mirënjohje speciale.
        Shoqata iu ndanë çertifikata dhe mirënjohje të gjithë garuesve fitues në Garat
        komunale, Garat shtetërore dhe Olimpiadën kosovare të fizikës,

        mësimdhënësve/ mentorëve të garuesve, personave për punën vullnetare si dhe
        institucioneve të cilët kanë kontribuar në organizimin e tyre.
    </div>
    <div class="text">
        <div class="subsub_title">Kriteret për përcaktimin e fituesve të Garave komunale</div>
        <div class="neni">Neni 45.</div>
        <ul class="list">
            <li>Garuesit më të sukseshëm, sipas pikëve të arritura në gara, fitojnë vendin e parë, të dytë ose të tretë.</li>
            <li>Mirënjohje nderi u jepen garuesve që kanë fituar pikë por nuk fituan vende.</li>    
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Kriteret për përcaktimin e fituesve të Garave shtetërore</div>
        <div class="neni">Neni 46.</div>
        <ul class="list">
            <li>Vendin e parë e fitojnë 8% e garuesve më të suksesshëm. Vendin e dytë
            25% e garuesve të parë por që kanë nuk kanë fituar vendin e parë dhe
            vendin e tretë 50% e garuesve të parë por që nuk kanë fituar vendin e parë
            ose të dytë.</li>
            <li>Mirënjohje nderi u jepen garuesve që kanë fituar pikë por nuk fituan vende
            dhe renditen ndër 67% e parë.</li>  
            <li>Me rastin e përcaktimit të numrit të nxënësve që fitojnë vende dhe mirënjohje, rrumbullakimi bëhet sipas natyrës numri më të afërt.</li> 
            <li>Shoqata, me propozim të Komisionit përkatës të garave, mund të ndajë edhe
            mirënjohje të tjera speciale, si mirënjohje për zgjidhjen origjinale të detyrës,
            mirënjohje të veçantë për mësimdhënësit më të suksesshëm etj.</li>
        </ul>
    </div>
    <div class="text">
        <div class="subsub_title">Ndarja e shpërblimeve, çertifikatave dhe mirënjohjeve</div>
        <div class="neni">Neni 47.</div>
        Në përfundim të Olimpiadës kosovare të fizikës mbahet një ceremoni solemne ku
        garuesve iu jepen çertifikata dhe mirënjohje nderi, mirënjohje për
        mësimdhënësit/ mentorët e tyre, mirënjohje për sponsorët dhe pjesëmarrsit në
        organizimin e garave dhe çmime të tjera speciale.
        Në këtë rast, solemnisht shpallet ekipi olimpik i Kosovës për pjesëmarrje në
        Olimpiadat ndërkomtare.
    </div>
    <div class="text">
        <div class="subsub_title">Arkivimi i dokumentacionit</div>
        <div class="neni">Neni 48.</div>
        <ul class="list">
            <li>Të gjitha çertifikatat, mirënjohjet etj të cilat i ndan Shoqata duhet të jenë të numëruara, të nënshkruara dhe të vulosura me vulën e Shoqatës.</li>
            <li>Procesverbalet e shpërblimeve si dhe dokumentacioni i garave ruhen në arkivin e Shoqatës.</li>  
        </ul>
    </div>
    <div class="text">
        <div class="sub_title">X. DISPOZITAT PËRFUNDIMTARE</div>
        <div class="subsub_title">Vendosja për çështje të caktuara</div>
        <div class="neni">Neni 49.</div>
        Për të gjithë pjesëmarrësit në organizimin e të gjitha niveleve të garave
        kërkohet që në mënyrë strikte ti përmbahen kësaj Rregulloreje. Për të gjitha
        çështjet që mund të lindin në të gjitha fazat e garave, të cilat nuk janë të
        rregulluara apo të përcaktuara me këtë Rregullore, vendimet i merr Kryesia i
        Shoqatës.
    </div>
    <div class="text">
        <div class="subsub_title">Raportimi i aktiviteteve të Shoqatës</div>
        <div class="neni">Neni 50.</div>
        Kryesia i Shoqatës merr masa dhe e përgatit Raportin e aktiviteteve të Shoqatës
        dhe Raportin financiar të cilat ia paraqet institucioneve kompetente jo më larg se
        15 ditë nga përfundimi i të gjitha aktiviteteve.
    </div>
    <div class="text">
        <div class="subsub_title">Pranimi i rregullores</div>
        <div class="neni">Neni 51.</div>
        Rregullorja për mbajtjen e garave fizikës për nxënësit e shkollave të mesme në
        Republikën e Kosovës miratohet nga Kryesia e Shoqatës dhe hyn në fuqi ditën e
        miratimit nga Kryesia e Shoqatës. E njëjta procedurë zbatohet edhe për
        miratimin e ndryshimeve dhe shtesave në këtë Rregullore.
    </div>
    <div class="bottom">
        <div>
            Prishtinë, 1 shtator, 2022
            
        </div>
        <div>
            Kryetar i Shoqatës Kosovare të Fizikës<br>
            Prof. Dr. Sadik Bekteshi
        </div>
    </div>
</template>
<style>
    .title_ss{
        margin-left: 7vw;
        margin-right: 7vw;
        text-align: center;
        font-size: 3vw;
        font-weight: 500;
        color: var(--title-color);
    }
    @media (min-width: 1400px){
        .title_ss{
            margin-left: 98px;
            margin-right: 98px;
            font-size: 42px;
        }
    }
</style>