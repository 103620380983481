<template>
    <div class="title_s">Garat Shkollore të fizikës</div>
    <div class="text">
        Garat shkollore organizohen nga aktivi i mësimdhënësve të fizikës në shkolla.
        Këto gara organizohen për nxënësit e klasëve të 9, 10, 11 dhe 12 të shkollave të
        mesme të ulëta dhe të mesme të larta.
    </div>
    <div class="text">
        Nxënësit që kanë arritur rezultatet më të mira në këto gara marrin pjesë në
        Garat komunale. Numrin e nxënësve pjesëmarrës në Garat komunale nga secila
        shkollë e përcakton DKA komunale.
    </div>
    <div class="text">
        Niveli përfundimtar i garave për shkollat e mesme të ulëta është Gara shetërore.
        Në të gjitha nivelet e garave, garat organizohen në zgjidhjen e detyrave teorike
        (numerike) nga lëmia e fizikës.
    </div>
    <div class="text">
        Niveli përfundimtar i garave për shkollat e mesme të ulëta është Gara shetërore.    
    </div>
    <div class="text">
        Në të gjitha nivelet e garave, garat organizohen në zgjidhjen e detyrave teorike
        (numerike) nga lëmia e fizikës.
    </div>
    <div class="text">
        Për detale shih 
        <router-link to="/Garat/Rregullorja" class="link"><div class="neni" @click="$emit('change_path')">RREGULLORE PËR MBAJTJEN E GARAVE TË FIZIKËS PËR NXËNËSIT E
        SHKOLLAVE TË MESME TË ULËTA DHE TË LARTA TË REPUBLIKËS SË KOSOVËS</div></router-link>
    </div>
</template>