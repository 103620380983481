<template>
    <div class="title_s">Garat në vitin shkollor 2023</div>


    <div class="text">
        <div class="sub_title center border_top">OLIMPIADA KOSOVARE NË FIZIKË 2023</div>
        <div class="center">
            <div style="color: #0d47a3;">
                Olimpiada kosovare në fizikë 2023 do të mbahet më:<br>
                    <div style="color: #c40c0c;">18.03.2023 (e shtunë), në ora 11.00<br></div>
                në Departamentin e fizikës të FSHMN, Prishtinë<br>
            </div>   
        </div>
    </div>
    <div class="text">
        <div class="sub_title">Më 18.03.2023, e shtunë</div>
        <table>
            <tr>
                <td class="width_or top">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          – 10.00<br>
                    10.15 – 10.45<br>
                    10.45 – 11,00<br>
                    11.00 – 13.00<br>
                </td>
                <td class="">
                    Mbledhja e garuesve<br>
                    Shujta<br>
                    Vendosja e garuesve në klasa<br>
                    Mbajtja e Olimpiadës<br>
                </td>
            </tr>
        </table>
    </div>
    <div class="text">
        <div class="sub_title">Më 21.03.2023, e martë</div>
        Publikimi i rezultateve preliminare<br>
        në web faqen e Shoqatës (<a href="shkf-ks.org"  class="link">shkf-ks.org</a>)
    </div>
    <div class="text">
        <div class="sub_title">Më 23.03.2023, e enjte</div>
        Shqyrtimi i ankesave eventuale. Komunikimi në lidhje me ankesat eventuale bëhet vetëm përmes emailit të shoqatës: <span class="link">shoqatakosovareefizikes@gmail.com</span>
    </div>
    <div class="text">
        <div class="sub_title">Më 24.03.2023, e premte</div>
        Publikimi I rezultateve përfundimtare <br>
        në web faqen e Shoqatës (<a href="shkf-ks.org"  class="link">shkf-ks.org</a>) dhe në faqen e Shoqatës  <a href="https://www.facebook.com/ShoqataKosovareeFizikes"  class="link">https://www.facebook.com/ShoqataKosovareeFizikes</a>
    </div>
    <div class="text">
        <div class="sub_title">Më 25.03.2023, e shtunë</div>
        <table>
            <tr>
                <td class="width_or top">
                    11.00 – 12.00<br>
                </td>
                <td class="">
                    Ceremonia e shpërndarjes së Çertifikatave dhe Mirënjohjeve dhe Mbyllja e Olimpiadës.<br>
                </td>
            </tr>
        </table>
    </div>
    <div class="text">
        <div class="sub_title center">PROGRAMI I OLIMPIADËS KOSOVARE NË FIZIKË 2023</div>
    </div>
    <div class="text">
        <div class="sub_title">Pjesëmarrja</div>
        Të drejtë pjesëmarrje në Olimpiadën kosovare të fizikës 2023 kanë të gjithë garuesit e klasëve të 10, 11
        dhe 12 të cilët kanë fituar vendin e parë, të dytë ose të tretë në Garat e XXIV shtetërore në fizikë 2023.
        Në Olimpiadën kosovare si dhe ato ndërkombtare mund të marrin pjesë vetëm nxënësit e shkollave të
        mesme.
    </div>
    <div class="text">
        <div class="sub_title">Regjistrimi</div>
        Regjistrimi për pjesëmarrje në Olimpiadë do të bëhet përmes emailit të Shoqatës
        shoqatakosovareefizikes@gmail.com deri më 15. 03.2023 ora 17.00. duke i dërguar të dhënat: Emri dhe
        mbiemri, klasa, shkolla, komuna. Pas këtij afati, regjistrimi nuk do të jetë i mundur.
    </div>
    <div class="text">
        <div class="sub_title">Detyrat</div>
        Në këtë Olimpiadë, detyrat do të jenë të njëjta për të gjitha klasët (sipas standardeve të olimpiadave ndërkombtare).
    </div>
    <div class="text">
        <div class="sub_title">Pjesëmarrja në Olimpiadat Nërkombëtare</div>
        Nga fituesit e Olimpiadës kosovare të fizikës 2023 do të përzgjidhen dy ekipe të cilat do ta përfaqsojnë
        Kosovën në Olimpiadat ndërkombtare, për detale shih Olimpiada e Kosovës/ndërkombtare në web faqen e Shoqatës (<a href="shkf-ks.org"  class="link">shkf-ks.org</a>)
    </div>




    <div class="text">
        <div class="sub_title center border_top">PROGRAMI I GARAVE TË XXIV SHTETËRORE NË FIZIKË 2023</div>
        <div class="textblue">
            Garat e XXIV shtetërore në fizikë 2023 do të mbahen më 25.02.2023 (e shtunë), në ora 11.00 në
            Sh.F. “Abdyl Frashëri” në Prizren (shkolla ngjitur me DKA) Rr. Ali Hadri Ortakoll, Prizren 20000. Abdyl
            Frashëri (në Google Maps: <a href="https://goo.gl/maps/QGVxPrJWrhHrdJuu5"  class="link">https://goo.gl/maps/QGVxPrJWrhHrdJuu5.</a>).
        </div>
    </div>
    <div class="text">
        <div class="sub_title">Më 25.02.2021, e shtunë</div>
        <table>
            <tr>
                <td class="width_or top">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– 10.00<br>
                    10.00 – 10.15<br>
                    10.15 – 10.45<br>
                    10.45 – 11,00<br>
                    11.00 – 13.00<br>
                </td>
                <td class="">
                    Mbledhja e garuesve në oborrin e shkollës<br>
                    Fjalë rasti<br>
                    Shujta<br>
                    Vendosja e garuesve në klasa<br>
                    Mbajtja e garës<br>
                </td>
            </tr>
        </table>
    </div>
    <div class="text">
        <div class="sub_title">Më 28.02.2023, e martë</div>
        Publikimi i rezultateve preliminare<br>
        në web faqen e Shoqatës (<a href="shkf-ks.org"  class="link">shkf-ks.org</a>)
    </div>
    <div class="text">
        <div class="sub_title">Më 02.03.2023, e enjte</div>
        Shqyrtimi i ankesave eventuale. Komunikimi në lidhje me ankesat eventuale bëhet vetëm përmes emailit të shoqatës: <span class="link">shoqatakosovareefizikes@gmail.com</span>
    </div>
    <div class="text">
        <div class="sub_title">Më 03.03.2023, e premte</div>
        Publikimi I rezultateve përfundimtare <br>
        në web faqen e Shoqatës (<a href="shkf-ks.org"  class="link">shkf-ks.org</a>) dhe në faqen e Shoqatës  <a href="https://www.facebook.com/ShoqataKosovareeFizikes"  class="link">https://www.facebook.com/ShoqataKosovareeFizikes</a>
    </div>
    <div class="text">
        <div class="sub_title center">Mbyllja e garave</div>
    </div>



    <div class="text">
        <div class="sub_title center border_top">Orari i fillimit të garave shkollore, komunale, shtetërore dhe Olimpiadës shtetërore e fizikës.</div>
        Me qëllim të avansimit të organizimit të garave, pjesëmarrjes më të gjërë të
        nxënësve në gara, përgaditjes më të mirë të nxënësve për gara etj dhe duke
        zbatuar standardet ndërkombtare të garave, Shoqata ka sjell disa risi në
        organizimin e garave. Kështu, këtë vit për herë të parë do të organizohet edhe
        Olimpiada shtetërore e fizikës. Poashtu për herë të parë do të organizohen edhe
        gara nga pjesa eksperimentale e fizikës. Për informata më detale shih
        <router-link to="/Garat/Rregullorja" style="text-decoration:none;"><div class="link" @click="$emit('change_path')">Rregulloren për mbajtjen e garave.</div></router-link>
        Garat shkollore, komunale, shtetërore dhe Olimpiada kosovare e fizikës
        parashihen të mbahen sipas orarit të dhënë. Nëse eventualisht ka ndryshim të
        datave Shoqata obligohet që rregullisht dhe me kohë të jap informatat e
        nevojshme.<br>
        <br>
        <table>
            <tr>
                <td class="neni width_td">Garat shkollore në fizikë</td>
                <td class="texttop">Gjatë janarit 2023.</td>
            </tr>
            <tr>
                <td class="neni width_td">Garat komunale në fizikë</td>
                <td class="texttop">11.02.2023 në ora 11.00 në vendin të cilin e cakton DKA e komunës</td>
            </tr>
            <tr>
                <td class="neni width_td">Garat shtetërore në fizikë</td>
                <td class="texttop">25.02.2023 në ora 11.00 në Sh.F. “Abdyl
                    Frashëri” në Prizren (shkolla ngjitur me
                    DKA)
                    Rr. Ali Hadri Ortakoll, Prizren 20000.
                    Abdyl Frashëri on Google Maps:
                    <a href="https://goo.gl/maps/QGVxPrJWrhHrdJuu5"  class="link">https://goo.gl/maps/QGVxPrJWrhHrdJuu5.</a></td>
            </tr>
            <tr>
                <td class="neni width_td">Olimpiada kosovare e fizikës</td>
                <td class="texttop">11.03.2023 në ora 11.00 në Departamentin e fizikës të FSHMN, Prishtinë.</td>
            </tr>
        </table> 
        <div class="text text_3 border_top">
                Shënim: <br>
                Në të gjitha nivelet e garave, nxënësit mund të përdorin mjetet e tyre të
                shkrimit dhe vizatimit, një kalkulator xhepi të pa programueshëm, një mjet gjeometrik
                (vizore apo trekëndësh) dhe materiale tjera të cilat i vë në dispozicion organizatori i
                garave. Gjatë garave, nxënësi nuk duhet të ketë asnjë material të shkruar (libra, fletore,
                dhe mjete të ngjashme), telefona celularë ose pajisje tjera elektronike.
        </div>
    </div>
</template>

<style>
.texttop{
    vertical-align: text-top;
    padding-bottom: 4vw;
}
.center{
    text-align: center;
}
.width_td{
    vertical-align: text-top;
    width: 29vw;   
}
.width_or{
    width: 18vw;
}
@media (min-width: 1400px){
    .width_td{
        width: 400px;   
    }
    .width_or{
        width: 252px;
    }
}
</style>
