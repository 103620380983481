<template>
    <div class="title_s">Historiku</div>
    <div class="text">
        <div class="sub_title">RETH NESH</div>
        Shoqata e Kosovare e Fizikës (SHKF) u rithemelua më 14 gusht 2000 me qëllim
        të promovimit dhe zhvillimit të aktiviteteve shkencore dhe edukative në fushën e
        fizikës. SHKF -ja vazhdon punën dhe traditat e Shoqatës së Fizikanëve të
        Kosovës, e cila doli nga Shoqata e Matematikanëve, Fizikanëve dhe
        Astronomëve të Kosovës (e themeluar më 1981), e cila ishte anëtare e
        Bashkimit të Shoqatave të Matematikanëve, Fizikanëve dhe Astronomëve te ish-
        Jugosllavisë. <br>
        Aktivitetet më të rëndësishme të organizuara në të kaluarën nga Shoqata ishin:
    </div>
    <ul class="list">
        <li>pjesëmarrja në garat e fizikanëve të rinj të ish-Jugosllavisë,</li>
        <li>pjesëmarrja në organizimin e garave të fizikanëve të rinj të ish-
        Jugosllavisë, njëra prej tyre u mbajt në Deçan (Kosovë) dhe</li>
        <li>organizimi i Kongresit VIII të Bashkimit të Shoqatave të Matematikanëve,
        Fizikanëve dhe Astronomëve të Jugosllavisë, të mbajtur në Prishtinë (Kosovë) në
        nëntor 1985.</li>
        <li>Të theksojmë se në këtë Kongres u pranua ideja për themelimin e Balkan
        Physical Union – një konferencë ballkanike respektabile e fizikës.</li>
    </ul>
    <div class="text">
        Fizikanëve dhe Astronomëve të Jugosllavisë, të mbajtur në Prishtinë (Kosovë) në nëntor 1985. <br>
        Të theksojmë se në këtë Kongres u pranua ideja për themelimin e Balkan Physical Union – një konferencë ballkanike respektabile e fizikës.
    </div>
    <div class="text">
        <div class="sub_title">ORGANIZIMI I GARAVE</div>
        Garat komunale dhe republikane mbahen në mënyrë të vazhdueshme që nga
        krijimi i Shoqatës me disa ndërprerje për arsye objektive. <br>
        Shoqata Kosovare e Fizikës për ҫdo vit organizon garat komunale dhe
        republikane të fizikanëve të rinj në të cilat marrin pjesë nxënësit e klasëve nga
        IX – XII. <br>
        Në garat komunale marrin pjesë nxënësit më të mirë të një komune me ҫ’rast
        përzgjedhen nxënësit më të sukseshëm. Nxënësit e përzgjedhur nëpër komuna
        pastaj marrin pjesë në garat republikane. <br>
        Në garat republikane, nxënësit më të sukseshëm vlerësohen me vendin e parë, e
        dytë dhe të tretë. Nxënësit më të mirë përgaditen dhe marrin pjesë në
        olimpiada ndërkombtare të fizikës. <br>
    </div>
    <div class="text">
        <div class="sub_title">PJESËMARRJA NË OLIMPIADA NDËRKOMBTARE TË FIZIKËS</div>
        Në katër vitet e fundit, ekipi olimpik i Kosovës i përzgedhur nga Shoqata ka marr
        pjesë në olimpiadat ndërkombtare të fizikës:
    </div>
    <ul class="list">
        <li>Olimpiada e 3-të Europiane e Fizikës EuPhO 2019, 31 maj – 4 qershor
        2019, Riga, Letoni.</li>
        <li>Olimpiada e 49-të Ndërkombtare e Fizikës IPhO 2019, 31– 4 korrik 2019,
        Tel Aviv, Izrael.</li>
        <li>Olimpiada e 4-të Europiane e Fizikës EuPhO 2020, 2 – 6 qershor 2020,
        Bukuresht, Rumani (online për shkak të pandemisë).</li>
        <li>Olimpiada e 50-të Ndërkombtare e Fizikës IPhO 2020, 18– 26 korrik
        2020, Vilnius, Lituani. (anuluar për shkak të pandemisë).</li>
        <li>Olimpiada e shpërndarë Ndërkombtare e Fizikës IdPhO 2020, 7– 15
        dhjetor 2020, Federata Ruse. (online - anuluar për shkak të moslejimit
        për pjesëmarrje).</li>
        <li>Olimpiada e 5-të Europiane e Fizikës EuPhO 2021, 19 – 26 qershor 2021,
        (online).</li>
        <li>Olimpiada e 51-të Ndërkombtare e Fizikës IPhO 2021, 31– 4 korrik 2021,
        Lituani. (online)</li>
        <li>Olimpiada e 6-të Europiane e Fizikës EuPhO 2022, 20 – 24 maj 2022,
        Ljubjanë, Slloveni.</li>
        <li>Olimpiada e 52-të Ndërkombtare e Fizikës IPhO 2022, 10– 17 korrik
        2022, Zvicër. (online)</li>
    </ul>
</template>

<style scoped>
 .sub_title{
    color: #2283C7;
 }

</style>
