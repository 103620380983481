<template>
    <div class="text">   
        Duke u bazuar në të drejtën për liri të asociimit të garantuar me nenin 65.1
        të Kushtetutës së Republikës së Kosovës dhe Ligjin për Liri të Asociimit në
        OJQ 04/L-57, Kuvendi i Shoqatës së Fizikanëve të Kosovës të mbajtur më 05
        04, 2018 miratoi:
    </div>
    <div class="title_ss">Statuti i Shoqatës Kosovare të Fizikës</div>
    <div class="text">
        <div class="sub_title">I. DISPOZITAT THEMELORE</div>
        <i class="neni">Neni 1.</i><br>
        Ky Statut përcakton rregullat për:
    </div>
    <ul class="list">
        <li>Emrin e Shoqatës</li>
        <li>Objektivat dhe aktivitetet e Shoqatës</li>
        <li>Procedurat e zgjedhjes dhe shkarkimit të anëtarëve dhe organeve administrative të Shoqatës, si dhe detyrat dhe përgjegjësitë e tyre</li>
        <li>Procedurat për ndryshimin e Statutit</li>
        <li>Çështje të tjera që lidhen me qeverisjen e brendshme të Shoqatës</li> 
    </ul>
    <div class="text">
        <i class="neni">Neni 2.</i><br>
        Emri i plotë i Shoqatës është Shoqata Kosovare e Fizikës (në tekstin e
        mëtejmë të Shoqata)
        Shoqata Kosovare e Fizikës është organizatë joqeveritare, profesionale dhe
        jofitimprurëse e themeluar nga persona fizikë.
        Emri i shkurtuar i Shoqatës është: SHKF.
        Selia e Shoqatës është në Prishtinë.
        Shoqata është një person juridik me detyra dhe përgjegjësi në përputhje të
        plotë me ligjet pozitive dhe Statutin e Shoqatës.
        Shoqata është e pavarur në aktivitetet e saj.<br>
    </div>
    <div class="text">
        <i class="neni">Neni 3.</i><br>
        Shoqata ka vulën dhe simbolin e saj. Vula është formë rrethore me
        mbishkrim në dy gjuhë: në shqidiv: &quot; Shoqata Kosovare e Fizikës &quot; dhe në
        anglisht: &quot;Kosovar Physical Society&quot;. Vula përdoret për dokumentacione dhe
        postë.
    </div>
    <div class="text">
        <i class="neni">Neni 4.</i><br>
        Aktiviteti i Shoqatës është publik.<br>
        Akti bazë i Shoqatës është Statuti.
    </div>
    <div class="text">
        <i class="neni">Neni 5.</i><br>
        Kryetari i Shoqatës e përfaqëson atë. Kryetari mund të autorizojë çdo anëtar
        të Kryesisë për të përfaqësuar Shoqatën në raste të caktuara.
    </div>
    <div class="text">
        <div class="sub_title">II. OBJEKTIVAT DHE AKTIVITETET E SHOQATËS</div>
        <i class="neni">Neni 6.</i><br>
        Shoqata është një organizatë jo-qeveritare, jo-fitimprurëse, shoqatë
        profesionale e themeluar me qëllim të përparimit dhe zhvillimit të shkencës,
        aktiviteteteve edukativo-arsimore dhe shoqërore në fushën e fizikës dhe
        fushave shkencore të lidhura me te si: astronomisë, gjeofizikës, biofizikës
        etj.
    </div>
    <div class="text">
        <i class="neni">Neni 7.</i><br>
        Fushat e veprimit të Shoqatës, në përputhje me objektivat e përcaktuara në
        nenin 7 të Statutit janë:
    </div>
    <ul class="list">
        <li>arsimi, shkenca dhe hulumtimi,</li>
        <li>bashkëpunimi ndërkombëtar.</li> 
    </ul>
    <div class="text">
        <i class="neni">Neni 8.</i><br>
        Grupet e synuara respektivisht shfrytëzuesit e shërbimeve të Shoqatës janë:
    </div>
    <ul class="list">
        <li>nxënësit,</li>
        <li>punëtorët e arsimit,</li> 
        <li>komuniteti akademik.</li>
    </ul>
    <div class="text">
        <i class="neni">Neni 9.</i><br>
        Objektivat më të gjera të Shoqatës Kosovare të Fizikës janë:
    </div>
    <ul class="list">
        <li>Inkurajimi i zhvillimit të fizikës dhe aplikimet e saj, veçanërisht në Kosovë.</li>
        <li>Shpërndarja e rezultateve të fundit të hulumtimit në fizikë përmes një gamë të gjerë aktivitetesh.</li> 
        <li>Aspektet mësimore, arsimore dhe kulturore të shkencës fizike në
        Kosovë, veçanërisht duke eksploruar mundësitë për futjen e zhvillimeve
        të fundit akademike ndërkombëtare dhe metodologjinë e re të
        mësimdhënies në planprogramin e shkollave fillore dhe të mesme në
        Kosovë dhe në Universitetin e Prishtinës.</li>
        <li>Integrimi në rrjetet mbarëbotërore nëpërmjet teknologjisë së
        informacionit, në lidhje me fizikën.</li>
        <li>Të kryejë një studim të thellë mbi mundësitë për bashkëpunim afatgjatë
        me universitetet e huaja dhe institucionet e tjera shkencore
        ndërkombëtare dhe të përpunojë më tej një bashkëpunim me ta.</li>
        <li>Motivimi i studentëve të rinj që tregojnë interes për fizikën për të
        vazhduar projektet ekzistente ose për të filluar projekte të reja kërkimore.</li>
        <li>Thellimi dhe zgjërimi i njohurive dhe aftësive profesionale të stafit
        akademik në Universitetin e Prishtinës dhe fizikantëve të tjerë anembanë
        Kosovës, veçanërisht të të rinjve që tregojnë interes për fizikën.</li>
        <li>Përdorimi i teknologjisë së informacionit në menaxhimin e bibliotekës
        dhe literaturës.</li>
    </ul>
    <div class="text">
        <i class="neni">Neni 10.</i><br>
        Objektivat kryesore të Shoqatës Kosovare të Fizikës duhet të zhvillohen
        përmes aktiviteteve të mëposhtme:
    </div>
    <ul class="list">
        <li>Organizimi i kongreseve, simpoziumeve, seminareve, konferencave,
        garave të fizikanëve të rinj, etj.</li>
        <li>Organizimi i vizitave kërkimore të shkencëtarëve të Kosovës në
        institucionet e huaja.</li>
        <li>Organizimi i vizitave të ekspertëve të huaj dhe shkencëtarëve në
        institucionet shkencore të Kosovës.</li>
        <li>Pjesëmarrje në kongrese, simpoziume, seminare dhe konferenca për
        fizikën në mbarë botën.</li>
        <li>Pjesëmarrja në projekte shkencore në bashkëpunim me ekipet e huaja
        të ekspertëve dhe institucioneve të ndryshme.</li>
        <li>Përpilimi i projekteve dhe hulumtimeve shkencore.</li>
        <li>Publikimi i revistave shkencore në lidhje me fizikën.</li>
        <li>Përgatitja e kurrikulave për shkollat fillore dhe të mesme të Kosovës.</li>
        <li>Organizimi i takimeve këshilluese për mësuesit e fizikës të shkollave
        fillore dhe të mesme të Kosovës.</li>
        <li>Promovimi dhe motivimi i universitetit cilësor dhe teksteve të tjera të
        fizikës si dhe librave cilësorë të fizikës në fusha të ndryshme të saj.</li>
        <li>Organizimi i orëve kompjuterike dhe të gjuhëve të huaja.</li>
        <li>Organizimi i shkollave verore.</li>
        <li>Drejtimi i filmave shkencorë dhe programeve televizive</li>
    </ul>
    <div class="text">
        <div class="sub_title">III. PROCEDURAT E ZGJEDHJES DHE SHKARKIMIT TË ANËTARËVE TË SHOQATËS</div>
        <i class="neni">Neni 11.</i><br>
        Anëtar i Shoqatës bëhet çdo person që pranon Statutin e Shoqatës dhe
        votohet nga shumica e anëtarëve të Kryesisë dhe Kuvendit, respektivisht.
        Anëtarët e Shoqatës mund të jenë:
    </div>
    <ul class="list">
        <li>Të rregullt</li>
        <li>Të merituar</li>
        <li>Të nderuar</li>
    </ul>
    <div class="text">
        Anëtarë të rregullt të Shoqatës mund të bëhen individë me aftësi të larta në
        fizikë dhe që punojnë në mënyrë aktive në fizikë dhe individë që shprehin
        gatishmërinë për të mbështetur Shoqatën në arritjen e objektivave të saj.
        Kuvendi propozon dhe miraton anëtarët meritorë të Shoqatës.
        Kryesia propozon dhe Kuvendi miraton anëtarët e nderuar
        Anëtarësimi në Shoqatë bëhet në mënyrë vullnetare.
    </div>
    <div class="text">
        <i class="neni">Neni 12.</i><br>
        Kryesia e Shoqatës pranon anëtarët e rinj dhe raporton për pranimin në
        mbledhjen e parë të radhës së Kuvendit.
        Të drejtat dhe obligimet e anëtarëve të Shoqatës fitohen me datën e
        regjistrimit në listën e anëtarëve.
    </div>
    <div class="text">
        <i class="neni">Neni 13.</i><br>
        Anëtari i Shoqatës mund të shkarkohet në rastet e mëposhtme:
    </div>
    <ul class="list">
        <li>Me kërkesën e tij</li>
        <li>Nëse nuk kryen detyrat dhe përgjegjësitë e tij</li>
        <li>Nëse sjellja e tij është kundër autoritetit të detyrave dhe përgjegjësive të tij</li>
        <li>Nëse humbet aftësinë për të kryer detyrat dhe përgjegjësitë e tij</li>
    </ul>
    <div class="text">
        <i class="neni">Neni 15.</i><br>
        Të drejtat dhe detyrat e anëtarëve të merituar dhe të nderuar të Shoqatës si
        dhe procedura e zgjedhjes së tyre përcaktohen në bazë të një rregulloreje të
        veçantë.
    </div>
    <div class="text">
        <i class="neni">Neni 16.</i><br>
        Të drejtat dhe detyrat e anëtarëve të merituar dhe të nderuar të Shoqatës si
        dhe procedura e zgjedhjes së tyre përcaktohen në bazë të një rregulloreje të
        veçantë.
    </div>
    <ul class="list">
        <li>Të zgjedhë dhe të zgjidhet për të gjitha organet administrative të Shoqatës</li>
        <li>Të informohet për aktivitetet e Shoqatës</li>
        <li>Të propozojë dhe të shprehë mendimin për aktivitetet e Shoqatës</li>
        <li>Të marrë pjesë aktivisht në punën e organeve administrative të Shoqatës</li>
        <li>Të marrin pjesë në të gjitha takimet e Shoqatës, të jenë aktivë me
            diskutimet dhe propozimet e tyre konstruktive dhe të vendosin për
            çështjet e rëndësishme të Shoqatës</li>
        <li>Të marrë pjesë në leksione, simpoziume, konferenca etj.</li>
        <li>Të gëzojnë të gjitha të drejtat sipas ligjit</li>
        <li>Të gëzojnë të gjitha përfitimet sipas rregullores financiare të Shoqatës</li>
        <li>Të gëzojnë mbështetjen e Shoqatës për zgjerimin e njohurive dhe aftësive profesionale</li>
        <li>Të vizitoj lokalet e Shoqatës, për të përdorur bibliotekën, dokumentacionin dhe shërbime të tjera të Shoqatës</li>
    </ul>
    <div class="text">
        <div class="sub_title">IV. ORGANET ADMINISTRATIVE DHE AUTORIZIMI I TYRE</div>
        <i class="neni">Neni 17.</i><br>
        Organet administrative të Shoqatës janë:
    </div>
    <ul class="list">
        <li>Kuvendi</li>
        <li>Kryetari</li>
        <li>Kryesia</li>
        <li>Sekretari</li>
        <li>Arkatari.</li>
    </ul>
    <div class="text">
        <div class="subsub_title">KUVENDI</div>
        <i class="neni">Neni 18.</i><br>
        Organi suprem i Shoqatës është Kuvendi i Shoqatës. Kuvendi përbëhet nga
        të gjithë anëtarët e Shoqatës. Mbledhjet e Kuvendit mbahen të paktën një
        herë në katër vjet. Mbledhjet e Kuvendit përcaktohen nga Kryesia, e cila
        poashtu e përcakton edhe rendin e ditës.<br>
        Rendi i ditës mund të ndryshohet me kërkesën e degëve ose seksioneve të
        Shoqatës. Në mbledhjet e Kuvendit, përzgjidhen anëtarët e degëve dhe
        seksioneve.<br>
        Delegacioni nga një degë ose seksion përbëhet nga 1 - 5 delegatë. Mandati i
        delegatit është 4 vjet. Delegati mund të zëvendësohet gjatë periudhës së
        mandatit të tij. Delegati është i detyruar të informojë degën /seksionin për
        aktivitetet e tij në Kuvend.<br>
        Vendimet e Kuvendit janë në fuqi nëse 50%+1 delegatë janë të pranishëm.
        Shumica merr vendimet.<br>
        Kuvendi i Shoqatës miraton Statutin me shumicën e votave të të gjithë
        anëtarëve të Shoqatës.<br>
        Shoqata i kryen aktivitetet në lidhje me aspektet arsimore dhe shkencore të
        fizikës, në përputhje të plotë me ligjet pozitive në Kosovë.
    </div>
    <div class="text">
        <i class="neni">Neni 19.</i><br>
        Kuvendi kryen detyrat në vazhdim:
    </div>
    <ul class="list">
        <li>Miraton Statutin dhe vendimet e marra sipas Statutit</li>
        <li>Analizon dhe vlerëson të arriturat dhe rregullsinë e aktiviteteve të
        Shoqatës dhe organeve të saj administrative për periudhën e mëparshme</li>
        <li>Analizon dhe miraton planin dhe programin e aktiviteteve dhe
        rekomandimet për periudhën në vijim</li>
        <li>Cakton delegatët e propozuar nga Kryesia për të përfaqësuar Shoqatën</li>
        <li>Miraton shkarkimin e statutit të mëparshëm, të organeve administrative
        si dhe zgjedhjen e statutit të ri dhe organeve administrative</li>
        <li>Zgjedh anëtarët meritorë dhe të nderuar të Shoqatës</li>
        <li>Vendos për ndërprerjen e aktiviteteve të Shoqatës</li>
        <li>Vendos për çështjet financiare të Shoqatës  </li>
    </ul>
    <div class="text">
        <i class="neni">Neni 20.</i><br>
        Mbledhjet e Kuvendit janë të rregullta ose të jashtëzakonshme.
        Mbledhja e jashtëzakonshme mblidhet me kërkesë të degëve, të së paku
        gjysma e degëve, ose me kërkesë të Kryesisë.
    </div>
    <div class="text">
        <i class="neni">Neni 21.</i><br>
        Kryesia është e detyruar të dërgojë ftesat për mbledhjen e Kuvendit të
        paktën 15 ditë para datës së caktuar për takimin.
        Kryetari i Shoqatës e udhëheq mbledhjen e Kuvendit. Votimi në Kuvend
        mund të jetë i hapur ose i fshehtë, siç vendos Kuvendi.
    </div>
    <div class="text">
        <div class="subsub_title">KRYETARI</div>
        <i class="neni">Neni 22.</i><br>
        Kryetari i Shoqatës përgjegjës ndaj Kuvendit. Ai përfaqëson Shoqatën dhe
        siguron funksionimin e duhur dhe të ligjshëm të Shoqatës. Në raste të
        caktuara, Kryetari mund të autorizojë çdo anëtar të Kryesisë të përfaqësojë
        Shoqatën. Kryetari i Shoqatës është edhe Kryetar i Kuvendit dhe Kryetari i
        Kryesisë. Në rast urgjence, kryetari mund të marrë vendime vetë.
    </div>
    <div class="text">
        <div class="subsub_title">KRYESIA</div>
        <i class="neni">Neni 23.</i><br>
        Kryesia ka se paku 3 anëtarë. Kryetarin, Sekretarin dhe Arkëtarin e
        Shoqatës. Kryesia është një organ ekzekutiv. Detyrat e saj janë si në vijim:
    </div>
    <ul class="list">
        <li>Të koordinojë aktivitetet e organeve administrative të Shoqatës në mes
        të dy mbledhjeve të Kuvendit</li>
        <li>Të përgatisë planin dhe programin e aktiviteteve të Shoqatës dhe të
        portojë në Kuvend</li>
        <li>Të thërrasë dhe të organizojë mbledhjet e Kuvendit</li>
        <li>Të përgatisë një raport vjetor financiar për Kuvendin</li>
        <li>Të përfaqësojë Shoqatën në institucionet shkencore në mbarë botën</li>
        <li>Të propozojë amendamente për ndryshimin e Statutit</li>
        <li>Përmbushja e marrëveshjeve me shoqëritë, organizatat dhe
        institucionet e tjera</li>
        <li>Te paraqesë mundësi punësimi me orar të plotë ose me kohë të
        pjesshme, nëse është e nevojshme</li>
    </ul>
    <div class="text">
        <i class="neni">Neni 24.</i><br>
        Kryesia ka se paku 3 anëtarë. Kryetarin, Sekretarin dhe Arkëtarin e Shoqatës. <br>
        Takimet e rregullta të Kryesisë mbahen së paku çdo tre muaj. <br>
        Kryesia siguron: 
    </div>
    <ul class="list">
        <li>përcakton propozimin e Statutit dhe ndryshimet dhe plotësimet e tij,</li>
        <li>përcakton planin e propozuar financiar dhe llogarinë përfundimtare,</li>
        <li>paraqet raportin vjetor të punës,</li>
        <li>vendosë për tarifën për anëtarësim,</li>
        <li>vendosë për pranimin dhe përjashtimin nga anëtarësia,</li>
        <li>vendosë për adresën e selisë së Shoqatës,</li>
        <li>kujdeset për informimin e anëtarëve dhe publikut,</li>
        <li>vendosë për përdorimin e aseteve të Shoqatës,</li>
        <li>krijon seksione, komisione dhe organe tjera të cilat ndihmojnë për të
        arritur objektivat e Shoqatës,</li>
        <li>kryen veprimtari të tjera që janë të nevojshme në kuadër të
        aktiviteteve të Shoqatës.</li>
    </ul>
    <div class="text">
        Takimet e Kryesisë mbahen sipas nevojës. <br>
        Kryesia merr vendime me shumicën e anëtarëve të Kryesisë. <br><br>
        Sekretari i Shoqatës:
    </div>
    <ul class="list">
        <li>i ndihmon Kryetarit të Shoqatës në punën e tij dhe kryen detyra të
        tjera të cilat ia cakton Kryetari i Kryesisë,</li>
        <li>mban listën e anëtarëve të Shoqatës,</li>
        <li>mban procesverbalin e mbledhjeve të Kuvendit dhe të Kryesisë,</li>
        <li>kryen detyra të tjera në pajtim me vendimet e Kryesisë.</li>
    </ul>
    <div class="text">
        Arkëtari i Shoqatës:
    </div>
    <ul class="list">
        <li>kryen punët financiare për nevojat e Shoqatës,</li>
        <li>kryen punë tjera financiare-materiale dhe të kontabilitetit për qëllime të Shoqatës,</li>
        <li>kryen edhe punë të tjera caktuara nga anëtarët e tjerë të Kryesisë dhe Kuvendit.</li>
    </ul>
    <div class="text">
        <div class="subsub_title">FUSHAT</div>
        <i class="neni">Neni 25.</i><br>
        Degët mund të formohen në komuna të ndryshme. Një degë mund të
        përbëhet nga së paku 10 anëtarë të Shoqatës. Iniciativa për krijimin e një
        dege mund të merret nga anëtarët e Shoqatës që janë aktivë në komunën
        përkatëse. <br>
        Dega drejtohet nga kryetari dhe sekretari i saj, i zgjedhur sipas procedurës
        së zgjedhjes së anëtarëve të Kuvendit. <br>
        Dega organizon aktivitetet e saj në nivel komune dhe më pas mundëson
        komunikimin me Kuvendin e Shoqatës. <br>
        Kryetari i degës është i detyruar t&#39;i raportojë Kuvendit për rezultatet dhe
        punën e degës. <br>
    </div>
    <div class="text">
        <div class="subsub_title">SEKSIONET</div>
        <i class="neni">Neni 26.</i><br>
        Nëse sfera e aktiviteteve të Shoqatës është shumë voluminoze, nëse është e
        nevojshme, mund të krijohen seksionet e mëposhtme:
    </div>
    <ul class="list">
        <li>Seksioni i aktiviteteve shkencore - Seksioni merret me çështje të lidhura
        me hulumtimin shkencor nga fusha e fizikës.</li>
        <li>Seksioni i aktiviteteve mësimore – I mbledh mësidhënësit e fizikës të
        shkollave fillore dhe të mesme si dhe të fakulteteve. Merret me aspektet
        shkencore dhe arsimore të mësimdhënies fizikës në shkollat fillore dhe të
        mesme dhe fakultete.</li>
        <li>Seksioni i popullarizimit të fizikës – Merret me popullarizimin e fizikës
        përmes televizionit, radios, gazetave, revistave, internetit etj.</li>
        <li>Seksioni i fizikës së zbatuar - Merret me promovimin dhe zbatimin e
        fizikës në institucione të ndryshme dhe ekonomi.</li>
        <li>Seksioni i studentëve - Merret me çështjet që janë me interes për
        studentët e fizikës në zhvillimin e tyre arsimor dhe hulumtues.</li>
    </ul>
    <div class="text">
        Seksioni ka kryetarin dhe sekretarin e saj. Ato propozohen dhe zgjidhen në Kuvend. <br>
        Seksionet raportojnë në Kuvend.
    </div>
    <div class="text">
        <div class="sub_title">V. BURIMET FINANCIARE</div>
        <i class="neni">Neni 27.</i><br>
        Burimet financiare që nevoiten për të realizuar objektivat dhe aktivitetet e
        Shoqatës mund të sigurohen:
    </div>
    <ul class="list">
        <li>Nga donacionet dhe ndihmat nga individët ose organizatat që japin
        kontribute drejt realizimit të objektivave të Shoqatës</li>
        <li>Nga aktivitetet e Shoqatës si organizimi i konferencave, simpoziumeve,
        takimeve këshillimore, orëve mësimore etj.</li>
        <li>• Nga aktivitetet botuese</li>
        <li>Nga shërbimet profesionale të tilla si vlerësimet profesionale, përpilimi i
        projekteve hulumtuese dhe raporte profesionale, hartimi i kurrikulave të
        fizikës etj.</li>
        <li>Nga bashkëpunimi me organizata dhe institucione të tjera</li>
    </ul>
    <div class="text">
        <i class="neni">Neni 28.</i><br>
        Burimet financiare menaxhohen sipas planit financiar të Shoqatës.
    </div>
    <div class="text">
        <i class="neni">Neni 29.</i><br>
        Kryetari është kompetent për menaxhimin e burimeve financiare dhe në
        mungesë të tij, një anëtar i Shoqatës i autorizuar nga Kryetari.
    </div>
    <div class="text">
        <div class="sub_title">VI. DISPOZITAT PËR ZGJEDHJET DHE SHKARKIMET</div>
        <i class="neni">Neni 30.</i><br>
        Kryetari i Shoqatës zgjedhet në mbledhjen e Kuvendit zgjedhor të thirrur nga
        Kryetari i Shoqatës. Kryetari i Shoqatës zgjedhet kandidati që ka 50% + 1
        vota nga e anëtarëve të Shoqatës me kusht që merr pjesë shumica e
        anëtarëve. <br>
        Sekretari dhe Arkatari i Shoqatës propozohen nga Kryetari dhe zgjedhen nga
        Kuvendi kandidatët të cilët kanë marrë numrin më të madh të votave të
        anëtarëve të Shoqatës. <br>
        Mandati i të gjitha organeve të Shoqatës zgjat katër vite. Mandati përfundon
        pas skadimit të kësaj kohe, shkarkimit ose pranimit të dorëheqjes në
        funksion. <br>
        Mandati mund të ndërpritet para skadimit, nëse anëtari nuk i kryen detyrat e
        tij ose nuk i kryen në përputhje me këtë Statut, me vendimet e Shoqatës ose
        ligjit. <br>
        Shkarkimi bëhet me procedurë të njëjtë si zgjedhja.
    </div> 
    <div class="text">
        <div class="sub_title">VII. DISPOZITAT PËRFUNDIMTARE DHE TË PËRKOHËSHME</div>
        <i class="neni">Neni 31.</i><br>
        Ndryshimi dhe plotësimi i këtij Statuti mund të bëhet sipas procedurës së miratimit. <br>
        Kryesia e Shoqatës është kompetente për interpretimin e këtij Statuti. <br>
        Ky Statut është në fuqi menjëherë pas miratimit në Kuvend.
    </div> 
    <div class="bottom">
        <div>
            Prishtinë, <br>
            06.04, 2018
        </div>
        <div>
            Kryetari i Shoqatës Kosovare të Fizikës<br>
            Prof. Dr. Sadik Bekteshi
        </div>
    </div>
</template>
<style>
    .title_s{
        margin-left: 7vw;
        margin-right: 7vw;
        text-align: center;
        font-size: 3.5vw;
        font-weight: 500;
        color: var(--title-color);
    }
    .list{
        margin-top: 0vw;
        font-size: 1.9vw;
        margin-left: 2vw;
        list-style-type: "–    ";
        color: var(--linecolor-color);
    }
    .neni{
        color:#0572C1;
        /* color: #43a7ff; */   
    }
    .sub_title{
        margin: 2vw;
        color: var(--subtitle-color);
        font-weight: 600;
    }
    .subsub_title{
        margin: 2vw;
        color: var(--subtitle-color);
        font-size: 1.7vw;
        font-weight: 600;
    }
    .bottom{
        color: var(--subtitle-color);
        display: flex;
        justify-content: space-between;
        margin-top: 4vw;
        text-align: center;
        font-size: 1.8vw;
    }    
    .margin_top{
        border-bottom: 0.1vw solid var(--linecolor-color);
        margin: 0.5vw;
        margin-bottom: 2vw;
        margin-top: 2vw;
        padding-bottom: 1vw;
    }
@media (min-width: 1400px){
    .title_s{
        margin-left: 98px;
        margin-right: 98px;
        font-size: 49px;
    }
    .list{
        margin-top: 0vw;
        font-size: 27px;
        margin-left: 2vw;
        list-style-type: "–    ";
    }
    .sub_title{
        margin: 28px;
    }
    .subsub_title{
        margin: 28px;
        font-size: 23.8px;
    }
    .bottom{
        margin-top: 42px;
        font-size: 26px;
    }  
    .margin_top{
        border-bottom: 1.4px solid var(--linecolor-color);
        margin: 7px;
        margin-bottom: 28px;
        margin-top: 28px;
        padding-bottom: 14px;
    }
}
</style>