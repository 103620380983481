<template>
    <div class="title_s">Garat shtetërore të fizikës</div>
    <div class="text">
        Shoqata Kosovare e Fizikës i organizon Garat shtetërore.
    </div>
    <div class="text">
        Në Garat shtetërore marrin pjesë nxënësit të cilët janë në listën e dërguar nga
        DKA-të komunale. Numrin e nxënësve pjesëmarrës në Garat shtetërore për çdo
        komunë e përcakton Shoqata varësisht nga madhësia e komunës dhe
        rezultateve të arritura në Garat komunale.
    </div>
    <div class="text">
        Fituesit e Garës shtetërore marrin pjesë në Olimpiadën kosovare të fizikës.
        Garuesit e olimpiadave të kaluara mund të marrin pjesë drejpërdrejt në Garat
        shtetërore.
    </div>
    <div class="text">
        Përgjegjëse për mbajtjen e Garave shtetërore është Shoqata.
    </div>
    <div class="text">
        Për detale shih 
        <router-link to="/Garat/Rregullorja" class="link"><div class="neni" @click="$emit('change_path')">RREGULLORE PËR MBAJTJEN E GARAVE TË FIZIKËS PËR NXËNËSIT E
        SHKOLLAVE TË MESME TË ULËTA DHE TË LARTA TË REPUBLIKËS SË KOSOVËS</div></router-link>
    </div>
</template>