<template>
    <div class="title_s">Këshilli organizativ i garave të fizikës</div>
    <div class="text">
        <table class="table">
            <tr>
                <td class="width_td top">Këshilli organizativ garave</td>
                <td class="textblue texttop">
                    Prof Dr Sadik Bekteshi
                    <br>Prof Dr Sefer Avdiaj
                    <br>Msc Ibrahim Hameli
                </td>
            </tr>
            <tr>
                <td class="width_td top">Komisioni shtetëror garave</td>
                <td class="textblue texttop">
                    Msc Burim Kamishi
                    <br>Msc Zeqë Tolaj
                    <br>Msc Njomza Elezaj
                    <br>Msc Ibrahim Hameli
                    <br>Msc Ardita Kurtishaj
                    <br>Msc Albert Januzaj
                </td>
            </tr>

            <tr>
                <td class="width_td top">Këshilli organizativ teknik i garave</td>
                <td class="textblue texttop">
                    Msc Hadije Zuçaku
                    <br>Astrit Sadiku
                    <br>Shkëndije Berisha
                    <br>Uran Daku
                    <br>Inesë Basha
                    <br>Halime Krasniqi
                    <br>Qëndresa Çitaku
                    <br>Zgjim Rrustemi
                    <br>Eduard Pajaziti
                    <br>Ardit Haxhijaj
                    <br>Afrora Krasniqi
                    <br>Brikena Gecaj
                    <br>Arsa Zeka
                    <br>Mihrije Krasniqi
                </td>
            </tr>
        </table> 
    </div>
</template>

<style>
    .table{
        margin-left: 10vw;
        margin-top: 2vw;
    }
    .top{
        display: flex;
        align-content: top;
    }
    .textblue{
        color: var(--textblue-color);
    }
@media (min-width: 1400px) {
    .table{
        margin-left: 140px;
        margin-top: 28px;
    }    
}
</style>