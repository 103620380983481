<template>
    <div class="title_s">Kryesia e Shoqatës</div>
    <div class="text">Shoqata kosovare e Fizikës udhëhiqet nga Kryesia e Shoqatës e përbërë nga:</div>
    <div class="text">
        <table>
            <tr>
                <td class="width width_td top">
                    Kryetari i Shoqatës<br>
                    Sekretar i Shoqatës<br>
                    Arkatar i Shoqatës<br>
                </td>
                <td class="textblue texttop">
                    Prof Dr Sadik Bekteshi<br>
                    Prof Dr Sefer Avdiaj<br>
                    Msc Zeqë Tolaj<br>
                </td>
            </tr>
            <tr>
                <td class="width width_td top">
                    Udhëheqës/e i seksionit:<br>
                    - aktiviteteve mësimore<br>
                    - për popullarizimin e fizikës<br>
                    - të fizikës së zbatuar<br>
                    - studentor të Shoqatës
                </td>
                <td class="textblue texttop">
                    <br>Msc Ardita Kurtishaj
                    <br>Astrit Sadiku
                    <br>Msc Yllka Delija
                    <br>Uran Daku
                </td>
            </tr>

            <tr>
                <td class="width width_td top">Këshilli organizativ i garave</td>
                <td class="textblue texttop">
                    Prof Dr Sadik Bekteshi
                    <br>Prof Dr Sefer Avdiaj
                    <br>Msc Ibrahim Hameli
                </td>
            </tr>
            <td class="width width_td top">
                    Udhëheqës<br>
                    - teknologjia e informacionit<br>
                    - çështje financiare dhe administrative<br>
            </td>
            <td class="textblue texttop">
                <br>Klevis Imeri
                <br>Bekim Syla
            </td>
        </table> 
    </div>
</template>
<style scoped>
.width{
    margin-bottom: 4vw;
    width: 40vw;
}
@media (min-width: 1400px) {
.width{
    margin-bottom: 56px;
    width: 560px;
}  
}
</style>