<template>
    <div class="title_s">Olimpiadat ndërkombtare të fizikës</div>
    
    <div class="text">
        <div class="sub_title">Përzgjedhja e pjesëmarrësve në olimpiada ndërkombtare</div>
        Lista përfundimtare e ekipit(ve) olimpik të Kosovës (si dhe dy anëtarë rezervë)
        përpilohet nga Shoqata në bazë të rezultateve arritura në Olimpiadën kosovare e
        fizikës dhe kushteve tjera për të cilat nxënësit duhet të njihen paraprakisht
        (rezultatet në Garat shtetërore, pjesëmarrja aktive në trajnime, zgjidhja e
        detyrave të dhëna nga mentorët etj) si dhe duke iu përmbajtur rregullores të
        olimpiadës nikoqire. Fituesi i vendit të parë mund të merrë pjesë në dy
        olimpiada kurse të tjerët në njërën nga olimpiadat ndërkombtare.
    </div>
    <div class="text">
        Numrin e pjesëmarrësve në olimpiada ndërkombtare e përcakton Kryesia e Shoqatës.
    </div>
    <div class="text">
        Në Olimpiada ndërkombtare nuk mund të marrin pjesë nxënësit që nuk kanë
        arritur së paku 15% të pikëve të përgjithshme në Olimpiadën kosovare të
        fizikës.
    </div>
    <div class="text">
        Deri më tani nxënësit kosovar kanë marr pjesë në:
        <ul class="list">
            <li><a class="neni link" href="https://eupho.ee/" >Olimpiadat Europiane të Fizikës (European Physics Olympiad EuPhO)</a></li>
            <li><a class="neni link" href="https://www.ipho-new.org/">Olimpiadat Ndërkombtare të Fizikës (International Physics Olympiad) IPhO</a></li>
        </ul>
    </div>
</template>