<template>
    <div class="title_ss">Shpërndarja e përmbajtjeve mësimore të detyrave sipas grupeve për shkolla të mesme të ulëta dhe të larta</div>
    <div class="text">
        Detyrat për të gjitha nivelet e garave dhe për të gjitha grupet përfshijnë përmbajtjen
        (temat) e kaluara të klasës përkatëse (temat e nënvizuara), si dhe përmbajtjen (temat)
        e të gjitha klasave të mëparshme, psh detyrat për klasën e 10 do të jenë detyra
        kryesisht nga temat e kaluara në gjysmëvjetorin e parë të klasës së 10 ((temat e
        nënvizuara)) si dhe detyra e klasës së 9 e kështu me rradhë.
    </div>
    <div class="text">
        <div class="subsub_title center">Grupi I</div>
        Përmbajtja (temat) e klasës së mëparshme si dhe përmbajtja (temat) e bazuar në
        programet mësimore të klasës së 9:
        <br>
        <br>
        <div class="u blue"> 1. Termodinamika, 2. Ngarkesat elektrike, 3. Ligjet e rrymës elektrike, 4. Fusha magnetike</div> 
        <br>
        <div class="blue">5. Induksioni elektromagnetik, 6. Përhapja drejtvizore e dritës, 7. Struktura e atomit dhe e bërthamës. 8. Fizika e gjysmëpërçuesve 9. Kozmologjia.</div>
    </div>
    <div class="text">
        <div class="subsub_title center">Grupi II</div>
        Përmbajtjet (temat) e klasëve të mëparshme si dhe përmbajtja (temat) e bazuar në programet mësimore të klasës së 10:
        <br>
        <br>
        <div class="u blue">1. Lënda e studimit të fizikës, 2. Lëvizjet e thjeshta dhe të përbëra, 3. Ligji i parë dhe
        ligji i dytë i Njutonit, 4. Ligji i tretë i Njutonit, 5. Energjia dhe ligji i ruajtjes së saj, 6.
        Ligji i Njutonit për gravitet.</div>
        <br>
        <div class="blue">7. Struktura e lëndës dhe gazet ideale, 8. Nxehtësia dhe format tjera të energjisë, 9.
        Ndërrimet e gjendjes agregate.</div>
    </div>
    <div class="text">
        <div class="subsub_title center">Grupi III</div>
        Përmbajtjet (temat) e klasëve të mëparshme si dhe përmbajtja (temat) e bazuar në programet mësimore të klasës së 11:
        <br>
        <br>
        <div class="u blue">1. Lëkundjet dhe valët, 2. Zëri, 3. Lëngjet në prehje dhe rrymimi i tyre, 4. Ngarkesat elek-trike dhe rrymat njëkahore,</div> 
        <br>
        <div class="blue">5. Fushat magnetike, 6. Forcat në fushën magnetike, 7. Induksioni elektromagnetik dhe rrymat alternative, 8. Fushat elektromagnetike, 9. Fotometria dhe madhësitë fotometrike</div>
    </div>
    <div class="text">
        <div class="subsub_title center">Grupi IV</div>
        Përmbajtjet (temat) e klasëve të mëparshme si dhe përmbajtja (temat) e bazuar në programet mësimore të klasës së 12:
        <br>
        <br>
        <div class="u blue">1. Optika gjeometrike, 2. Optika valore, 3.Teoria speciale e relativitetit (TSR), 4.Rrezatimi termik,</div> 
        <br>
        <div class="blue"> 5. Struktura e atomit, 6. Bërthama e atomit dhe grimcat elementare, 7. Fizika e trupit të ngurtë, 8. Ushtrime laboratori.</div>
    </div>
    <div class="text"><br><br><br>Rekomandohet që nxënësit në grupe të vogla të përgaditen për pjesëmarrjen në këto gara nën udhëheqjen e një mentori.</div>
    
</template>

<style>
.u{
    text-decoration: underline;
}
.blue{
    color: rgb(69, 175, 242);
}

</style>