<template>
    <div class="title_s">Olimpiada kosovare e fizikës</div>
    <div class="text">SHKF organizon Olimpiadën kosovare e fizikës.</div>
    <div class="text">
        Në Olimpiadën kosovare të fizikës marrin pjesë garuesit, të cilët kanë fituar
        vende në Garat shtetërore (përveç grupit 1 - nxënësve të klasëve të 9) dhe janë
        përzgjedhur nga Komisioni shtetëror.
    </div>
    <div class="text">
        Në përzgjedhjen e pjesëmarrësve për Olimpiadën kosovare të fizikës marrin
        pjesë fituesit e Garave shtetërore të fizikës. Komisioni shtetëror përzgjedh
        nxënësit më të mirë që do të marrin pjesë në Olimpiadën kosovare e fizikës.
    </div>
    <div class="text">
        Nga sivjet, në Olimpiadën kosovare të fizikës, nxënësit mund të garojnë edhe
        me punime eksperimentale. Fituesit e garave shtetërore mund të paraqesin
        punën e tyre të pavarur eksperimentale (së bashku me mentorin). drejtpërdrejt
        në SHKF, në e-mailin: shoqatakosovareefizikes@gmail.com.
    </div>
    <div class="text">
        Për detale shih 
        <router-link to="/Garat/Rregullorja" class="link"><div class="neni" @click="$emit('change_path')">RREGULLORE PËR MBAJTJEN E GARAVE TË FIZIKËS PËR NXËNËSIT E
        SHKOLLAVE TË MESME TË ULËTA DHE TË LARTA TË REPUBLIKËS SË KOSOVËS</div></router-link>
    </div>
</template>